import { IonButton, IonButtons, IonContent, IonDatetime, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonSelect, IonSelectOption, IonTitle, IonToolbar } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { close } from 'ionicons/icons';
import { useAuth } from '../AuthContext';
import { saveTransaction } from '../services/TransactionService';
import firebase from '../firebase';
import { useBudgets } from '../services/BudgetService';

interface TransactionDetailProps {
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    transactionToEdit: { id: string | undefined, data: firebase.firestore.DocumentData | undefined };
    setTransactionToEdit: (transaction: { id: string | undefined, data: firebase.firestore.DocumentData | undefined }) => void;
}

const TransactionDetail: React.FC<TransactionDetailProps> = ({ showModal, setShowModal, transactionToEdit, setTransactionToEdit }) => {
    const itemRef = useRef<HTMLIonInputElement>(null);
    const dateRef = useRef<HTMLIonInputElement>(null);
    const amountRef = useRef<HTMLIonInputElement>(null);

    const [item, setItem] = useState('');
    const [date, setDate] = useState('');
    const [budgetCategory, setBudgetCategory] = useState('');
    const [amount, setAmount] = useState('');
    const { currentUser } = useAuth();
    const userId = currentUser?.uid;
    const { budgets } = useBudgets();

    useEffect(() => {
        if (transactionToEdit.data) {
            setItem(transactionToEdit.data?.item || '');
            setDate(transactionToEdit.data?.date || '');
            setBudgetCategory(transactionToEdit.data?.budgetCategory || '');
            setAmount(transactionToEdit.data?.amount || '');
        } else {
            const currentDate = new Date();
            const formattedDate = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`;
            setDate(formattedDate);
            setBudgetCategory('');
            setAmount('');
            setItem('');
        }
    }, [transactionToEdit]);

    const handleSave = () => {
        const date = dateRef.current?.value as string;
        const amount = amountRef.current?.value as string;
        console.log(date);
        saveTransaction(userId!, transactionToEdit.id, { item, date, budgetCategory, amount: parseFloat(amount) });
        setShowModal(false);
        setTransactionToEdit({ id: undefined, data: undefined });
    };

    return (
        <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{transactionToEdit.id ? 'Edit Transaction' : 'Add Transaction'}</IonTitle>                    
                    <IonButtons slot="end">
                        <IonButton onClick={() => setShowModal(false)}>
                            <IonIcon slot="icon-only" icon={close} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <IonItem>
                    <IonLabel position="stacked">Item</IonLabel>
                    <IonInput type="text" ref={itemRef} value={item} onIonChange={e => setItem(e.detail.value!)} />
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Date</IonLabel>
                    <IonInput type="date" ref={dateRef} value={date} onIonChange={e => setDate(e.detail.value!)}></IonInput>
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Budget Category</IonLabel>
                    <IonSelect value={budgetCategory} placeholder="Select One" onIonChange={e => setBudgetCategory(e.detail.value)}>
                        {budgets.map(budget => (
                            <IonSelectOption key={budget.id} value={budget.data.categoryName}>
                                {budget.data.categoryName}
                            </IonSelectOption>
                        ))}
                    </IonSelect>
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Amount</IonLabel>
                    <IonInput type="number" ref={amountRef} value={amount} onIonChange={e => setAmount(e.detail.value!)} />
                </IonItem>
                <IonButton expand="full" onClick={handleSave}>Save</IonButton>
            </IonContent>
        </IonModal>
    );
};

export default TransactionDetail;
