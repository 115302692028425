import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Expenses from './pages/Expenses';
import Login from './pages/Login';
import { AuthContext, AuthProvider } from './AuthContext';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import PrivateRoute from './PrivateRoute';
import SideMenu from './components/SideMenu';
import Budgets from './pages/Budgets';
import CategoryTransactions from './pages/CategoryTransactions';

setupIonicReact();

const App: React.FC = () => (
  <AuthProvider>
    <IonApp>
      <IonReactRouter>
        <SideMenu />
        <IonRouterOutlet id="main">
          <Routes />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  </AuthProvider>
);

const Routes: React.FC = () => {
  const { loading } = useContext(AuthContext);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <PrivateRoute exact path="/expenses" component={Expenses} />
      <PrivateRoute exact path="/transactions" component={CategoryTransactions} />
      <PrivateRoute exact path="/budgets" component={Budgets} />
      <Route exact path="/">
        <Redirect to="/expenses" />
      </Route>
      <Route path="/login" component={Login} exact={true} />
    </>
  );
};

export default App;
