import {
    IonContent,
    IonHeader,
    IonMenu,
    IonToolbar,
    IonTitle,
    IonList,
    IonItem,
    IonLabel,
    IonMenuToggle,
    IonButton,
    IonIcon,
} from '@ionic/react';
import React, { useContext } from 'react';
import { AuthContext } from '../AuthContext';
import firebase from '../firebase';
import { logOutOutline, homeOutline, walletOutline } from 'ionicons/icons';

const SideMenu: React.FC = () => {
    const { currentUser } = useContext(AuthContext);

    const logoutUser = async () => {
        await firebase.auth().signOut();
    };

    return (
        <IonMenu side="start" menuId="first" contentId="main" swipeGesture={!!currentUser}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Menu</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    <IonMenuToggle auto-hide="false">
                        <IonItem>
                            <IonLabel>{currentUser?.email}</IonLabel>
                        </IonItem>
                        <IonItem button routerLink="/expenses" routerDirection="none" lines="none" detail={false}>
                            <IonIcon slot="start" icon={homeOutline} />
                            <IonLabel>Expenses</IonLabel>
                        </IonItem>
                        <IonItem button routerLink="/budgets" routerDirection="none" lines="none" detail={false}>
                            <IonIcon slot="start" icon={walletOutline} />
                            <IonLabel>Budgets</IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonButton expand="full" color="danger" onClick={logoutUser}>
                                Logout
                            </IonButton>
                        </IonItem>
                    </IonMenuToggle>
                </IonList>
            </IonContent>
        </IonMenu>
    );
};

export default SideMenu;
