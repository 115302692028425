import { IonModal, IonHeader, IonToolbar, IonTitle, IonContent, IonButton, IonInput, IonLabel, IonItem, IonButtons, IonIcon, IonAlert } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import firebase from '../firebase';
import { useAuth } from '../AuthContext';
import { close } from 'ionicons/icons';
import { saveBudget } from '../services/BudgetService';

interface BudgetDetailProps {
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    budgetToEdit: { id: string, data: firebase.firestore.DocumentData } | null;
    setBudgetToEdit: (budget: { id: string, data: firebase.firestore.DocumentData } | null) => void;
}

const BudgetDetailModal: React.FC<BudgetDetailProps> = ({ showModal, setShowModal, budgetToEdit, setBudgetToEdit }) => {
    const { currentUser } = useAuth();
    const userId = currentUser?.uid;
    const [showAlert, setShowAlert] = useState(false);
    const categoryNameRef = useRef<string>(budgetToEdit?.data.categoryName || '');
    const limitRef = useRef<string>(budgetToEdit?.data.limit || '');

    useEffect(() => {
        categoryNameRef.current = budgetToEdit?.data.categoryName || '';
        limitRef.current = budgetToEdit?.data.limit || '';
    }, [budgetToEdit]);

    const handleSave = async () => {
        if (categoryNameRef.current === '' || limitRef.current === '') {
            setShowAlert(true);
            return;
        }
        saveBudget(userId!, budgetToEdit?.id, { categoryName: categoryNameRef.current, limit: parseFloat(limitRef.current) });
        categoryNameRef.current = '';
        limitRef.current = '';
        setShowModal(false);
    };

    return (
        <IonModal isOpen={showModal} onDidDismiss={() => { setShowModal(false); }}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Budget Details</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => setShowModal(false)}>
                            <IonIcon slot="icon-only" icon={close} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonItem>
                    <IonLabel position="floating">Category Name</IonLabel>
                    <IonInput value={categoryNameRef.current} onIonChange={e => categoryNameRef.current = e.detail.value!} />
                </IonItem>
                <IonItem>
                    <IonLabel position="floating">Limit</IonLabel>
                    <IonInput type="number" value={limitRef.current} onIonChange={e => limitRef.current = e.detail.value!} />
                </IonItem>
                <IonButton expand="full" onClick={handleSave}>Save</IonButton>
            </IonContent>
            <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                header={'Input Error'}
                message={'Both category name and limit are required!'}
                buttons={['OK']}
            />
        </IonModal>
    );
};

export default BudgetDetailModal;
