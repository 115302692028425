// CategoryTransactions.tsx
import React, { useState } from 'react';
import { IonPage, IonContent, IonHeader, IonToolbar, IonTitle, IonList, IonItem, IonLabel, IonButton, IonBackButton, IonButtons, IonIcon, IonItemGroup, IonItemDivider } from '@ionic/react';
import { useTransactionsByOneCategory } from '../services/TransactionService';
import { formatCurrency } from '../utils';
import { useLocation } from 'react-router-dom';
import { addOutline } from 'ionicons/icons';
import firebase from '../firebase';
import TransactionDetail from '../components/TransactionDetail';

const CategoryTransactions: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [transactionToEdit, setTransactionToEdit] = useState<{ id: string | undefined, data: firebase.firestore.DocumentData | undefined }>({ id: undefined, data: undefined });
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const categoryName = queryParams.get('category');
  const month = Number(queryParams.get('month'));
  const year = Number(queryParams.get('year'));

  const { transactions, loading } = useTransactionsByOneCategory(categoryName!, month, year);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/expenses" />
          </IonButtons>
          <IonTitle>Transactions</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => setShowModal(true)}>
              <IonIcon slot="icon-only" icon={addOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {loading ? (
          <IonLabel>Loading...</IonLabel>
        ) : (
          <IonItemGroup>
            <IonItemDivider>
              <IonLabel>{categoryName} - {month}/{year}</IonLabel>
            </IonItemDivider>
            {transactions.length === 0 ? (
              <IonItem>No transactions found.</IonItem>
            ) : (
              transactions.map(({ id, data }) => (
                <IonItem key={id} onClick={() => { setTransactionToEdit({ id, data }); setShowModal(true); }}>
                  <IonLabel>
                    <h2>{data.item}</h2>
                    <p>{data.date}</p>
                  </IonLabel>
                  <IonLabel slot="end">{formatCurrency(data.amount)}</IonLabel>
                </IonItem>
              ))
            )}
          </IonItemGroup>
        )}
        <TransactionDetail showModal={showModal} setShowModal={setShowModal} transactionToEdit={transactionToEdit} setTransactionToEdit={setTransactionToEdit} />
      </IonContent>
    </IonPage>
  );
};

export default CategoryTransactions;
