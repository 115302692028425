import firebase from '../firebase';
import { useAuth } from '../AuthContext';
import { useEffect, useState } from 'react';

export function getMonthDocId(displayedMonth: Date) {
    const year = displayedMonth.getFullYear();
    const month = displayedMonth.getMonth() + 1; // JS months are 0-based
    return `${year}-${month < 10 ? '0' : ''}${month}`; // e.g., '2023-07'
}

export function useTransactions(displayedMonth: Date) {
    const { currentUser } = useAuth();
    const [transactions, setTransactions] = useState<{ id: string, data: firebase.firestore.DocumentData }[]>([]);
    const [loading, setLoading] = useState(true);
    const monthDocId = getMonthDocId(displayedMonth);

    useEffect(() => {
        const db = firebase.firestore();

        return db.collection('users').doc(currentUser?.uid)
            .collection('months')
            .doc(`${monthDocId}`) // You may have a different document ID structure
            .collection('transactions')
            .orderBy('date', 'desc')
            .onSnapshot(snapshot => {
                const fetchedTransactions = snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() }));
                setTransactions(fetchedTransactions);
                setLoading(false);
            });
    }, [currentUser]);

    return { transactions, loading };
}

// function to fetch transactions by category from firebase
export function useTransactionsByOneCategory(categoryName: string, month: number, year: number) {
    const { currentUser } = useAuth();
    const [loading, setLoading] = useState(true);
    const [transactions, setTransactions] = useState<{ id: string, data: firebase.firestore.DocumentData }[]>([]);

    useEffect(() => {
        const db = firebase.firestore();
        const monthDocId = `${year}-${String(month).padStart(2, '0')}`; // Ensure month is two digits
        return db.collection('users')
            .doc(currentUser?.uid)
            .collection('months')
            .doc(monthDocId)
            .collection('transactions')
            .where('budgetCategory', '==', categoryName)
            .onSnapshot(snapshot => {
                const fetchedTransactions = snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() }));
                setTransactions(fetchedTransactions);
                setLoading(false);
            });
    }, [currentUser]);
    return { transactions, loading };
}


export async function saveTransaction(userId: string, transactionId: string | undefined, transaction: { item: string, date: string, budgetCategory: string, amount: number }) {
    const db = firebase.firestore();
    const dateParts = transaction.date.split('-');
    const monthDocId = `${dateParts[0]}-${dateParts[1]}`;

    if (transactionId) {
        // Update existing transaction
        await db.collection('users').doc(userId)
            .collection('months').doc(monthDocId)
            .collection('transactions').doc(transactionId).update(transaction);
    } else {
        // Add new transaction
        await db.collection('users').doc(userId)
            .collection('months').doc(monthDocId)
            .collection('transactions').add(transaction);
    }
}

export function useTransactionsByCategory(displayedMonth: Date) {
    const { currentUser } = useAuth();
    const userId = currentUser?.uid;
    const [groupedTransactions, setGroupedTransactions] = useState<{ [category: string]: { transactions: { id: string, data: any }[], total: number } }>({});
    const [loading, setLoading] = useState(true);
    const monthDocId = getMonthDocId(displayedMonth);

    useEffect(() => {
        const db = firebase.firestore();
        return db.collection('users').doc(userId).collection('months').doc(monthDocId).collection('transactions')
            .onSnapshot(snapshot => {
                const fetchedTransactions = snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() }));

                // Group transactions by budget category and calculate totals
                const grouped = fetchedTransactions.reduce<{ [category: string]: { transactions: { id: string, data: any }[], total: number } }>((groups, transaction) => {
                    const category = transaction.data.budgetCategory;
                    if (!groups[category]) {
                        groups[category] = { transactions: [], total: 0 };
                    }
                    groups[category].transactions.push(transaction);
                    groups[category].total += parseFloat(transaction.data.amount);
                    return groups;
                }, {});

                setGroupedTransactions(grouped);
                setLoading(false);
            });
    }, [userId, displayedMonth]);

    return { groupedTransactions, loading };
}