import React, { createContext, useState, useEffect, useContext } from 'react';
import firebase from './firebase';

interface IAuthContext {
    currentUser: firebase.User | null;
    loading: boolean;
}

export const AuthContext = createContext<IAuthContext>({ currentUser: null, loading: true });

export const useAuth = () => {
    return useContext(AuthContext);
  };
  
type Props = {
    children?: React.ReactNode
};

export const AuthProvider: React.FC<Props> = ({ children }) => {
    const [currentUser, setCurrentUser] = useState<firebase.User | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            setCurrentUser(user);
            setLoading(false);
        });
    }, []);

    return (
        <AuthContext.Provider value={{ currentUser, loading }}>
            {children}
        </AuthContext.Provider>
    );
};
