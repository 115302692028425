import React, { useState } from 'react';
import { IonButton, IonContent, IonHeader, IonInput, IonLabel, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import firebase from '../firebase';
import { useHistory } from 'react-router-dom';


const Login: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [authError, setAuthError] = useState<string>('');

    const history = useHistory();  

    const loginUser = async () => {
        try {
            await firebase.auth().signInWithEmailAndPassword(email, password);
            // navigate the user to home
            history.push('/'); 
        } catch (error:any) {
            setAuthError(error.message);
        }
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Budgetor App</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding" fullscreen>
                <p className="ion-text-center">Please login to get started.</p>
                <IonInput placeholder="Email" onIonChange={(e: any) => setEmail(e.target.value)} />
                <IonInput placeholder="Password" type="password" onIonChange={(e: any) => setPassword(e.target.value)} />
                <IonLabel>{authError}</IonLabel>
                
                <IonButton expand="full" onClick={loginUser}>Login</IonButton>
            </IonContent>
        </IonPage>
    );
};

export default Login;
