import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonList, IonLoading, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../AuthContext';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { add } from 'ionicons/icons';
import BudgetDetailModal from '../components/BudgetDetail';
import { formatCurrency } from '../utils';
import { useBudgets } from '../services/BudgetService';

const Budgets: React.FC = () => {
    const { currentUser } = useAuth();
    const [showModal, setShowModal] = useState(false);
    const { budgets, loading } = useBudgets();
    const [budgetToEdit, setBudgetToEdit] = useState<{ id: string, data: firebase.firestore.DocumentData } | null>(null);
    const total = budgets.reduce((acc, budget) => acc + budget.data.limit, 0);
    const sortedBudgets = budgets.slice().sort((a, b) => b.data.limit - a.data.limit);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton ></IonMenuButton>
                    </IonButtons>
                    <IonButtons slot="end">
                        <IonButton onClick={() => {
                            setBudgetToEdit(null);
                            setTimeout(() => setShowModal(true), 0);
                        }}>
                            <IonIcon slot="icon-only" icon={add} />
                        </IonButton>
                    </IonButtons>
                    <IonTitle>Budgets</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonLoading isOpen={loading} message="Fetching budgets" />
                <IonList>
                    {sortedBudgets.length === 0 ? (
                        <IonItem>
                            <IonLabel>
                                No Records Found.
                            </IonLabel>
                        </IonItem>
                    ) : (
                        sortedBudgets.map(({ id, data }) => (
                            <IonItem key={id} button onClick={() => {
                                setBudgetToEdit({ id, data });
                                setTimeout(() => setShowModal(true), 0);
                            }}>
                                <IonLabel>
                                    <h2>{data.categoryName}</h2>
                                </IonLabel>
                                <IonLabel slot="end">{formatCurrency(data.limit)}</IonLabel>
                            </IonItem>
                        ))
                    )}
                    <IonItemGroup>
                        <IonItemDivider>
                            <IonLabel style={{ fontWeight: 'bold' }}>
                                <h2>Total</h2>
                            </IonLabel>
                            <IonLabel slot="end" style={{ fontWeight: 'bold' }}>{formatCurrency(total)}</IonLabel>
                        </IonItemDivider>
                    </IonItemGroup>
                </IonList>
            </IonContent>
            <BudgetDetailModal showModal={showModal} setShowModal={setShowModal} budgetToEdit={budgetToEdit} setBudgetToEdit={setBudgetToEdit} />

        </IonPage>
    )
};

export default Budgets;
