import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonLoading, IonMenuButton, IonNav, IonNavLink, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import firebase from '../firebase';
import { formatCurrency } from '../utils';
import { useTransactionsByCategory } from '../services/TransactionService';
import { addOutline, arrowBack, arrowForward } from 'ionicons/icons';
import TransactionDetail from '../components/TransactionDetail';
import { useBudgets } from '../services/BudgetService';

const Expenses: React.FC = () => {
  const [displayedMonth, setDisplayedMonth] = useState(new Date());
  const [showModal, setShowModal] = useState(false);
  const [transactionToEdit, setTransactionToEdit] = useState<{ id: string | undefined, data: firebase.firestore.DocumentData | undefined }>({ id: undefined, data: undefined });
  const { groupedTransactions, loading } = useTransactionsByCategory(displayedMonth);
  const { budgets } = useBudgets();
  const history = useHistory();

  // Create a map of budget categories
  const budgetsMap = budgets.reduce((map, { id, data }) => {
    map[data.categoryName] = { ...data, id, transactions: 0, total: 0 };
    return map;
  }, {} as { [category: string]: any });

  // Add transactions to budget categories
  Object.entries(groupedTransactions).forEach(([category, { transactions, total }]) => {
    if (budgetsMap[category]) {
      budgetsMap[category].transactions = transactions.length;
      budgetsMap[category].total = total;
    } else {
      budgetsMap[category] = { transactions: transactions.length, total, limit: 0 };
    }
  });

  // Previous and Next Month
  const incrementMonth = () => {
    const newDate = new Date(displayedMonth);
    newDate.setMonth(newDate.getMonth() + 1);
    setDisplayedMonth(newDate);
  };

  const decrementMonth = () => {
    const newDate = new Date(displayedMonth);
    newDate.setMonth(newDate.getMonth() - 1);
    setDisplayedMonth(newDate);
  };

  const isCurrentMonth = displayedMonth.getMonth() === new Date().getMonth() && displayedMonth.getFullYear() === new Date().getFullYear();

  const handleItemClick = (categoryName: string) => {
    history.push({
      pathname: '/transactions',
      search: `?category=${encodeURIComponent(categoryName)}&month=${displayedMonth.getMonth() + 1}&year=${displayedMonth.getFullYear()}`
    });
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>Expenses</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonLoading isOpen={loading} message="Fetching transactions" />
        <IonItem>
          <IonButton slot='start' onClick={decrementMonth}>
            <IonIcon icon={arrowBack} />
          </IonButton>
          <IonLabel className="ion-text-center">
            <h2> {displayedMonth.toLocaleString('default', { month: 'long' })} {displayedMonth.getFullYear()}</h2>
          </IonLabel>
          <IonButton slot='end' onClick={incrementMonth} disabled={isCurrentMonth}>
            <IonIcon icon={arrowForward} />
          </IonButton>
        </IonItem>
        {
          Object.entries(budgetsMap).map(([categoryName, { transactions, total, limit }]) => {
            
            return (
                <IonItem key={categoryName} button onClick={() => handleItemClick(categoryName)}>
                  <IonLabel>
                    <h2></h2>{categoryName}
                    <p>{transactions} Transactions</p>
                    </IonLabel>
                  <IonLabel slot="end" class="ion-text-end">
                    <h2>{formatCurrency(total)}</h2>
                    <p>{formatCurrency(limit)}</p>
                    </IonLabel>
                </IonItem>
            )
          })
        }
        {/* {Object.entries(groupedTransactions).map(([category, { transactions, total }]) => (
          <div key={category}>
            <h2>{category}</h2>
            <p>Total: {total}</p>
            <IonList>
              {transactions.length === 0 ? (
                <IonItem>No transactions found.</IonItem>
              ) : (
                transactions.map(({ id, data }) => (
                  <IonItem button key={id} onClick={() => { setTransactionToEdit({ id, data }); setShowModal(true); }}>
                    <IonLabel>
                      <h2>{data.budgetCategory}</h2>
                      <p>Date: {data.date}</p>
                    </IonLabel>
                    <IonLabel slot="end">{formatCurrency(data.amount)}</IonLabel>
                  </IonItem>
                ))
              )}
            </IonList>
          </div>
        ))} */}
      </IonContent>
    </IonPage>
  );
};

export default Expenses;
