// budgetsService.ts

import firebase from '../firebase';
import { useAuth } from '../AuthContext';
import { useEffect, useState } from 'react';

export function useBudgets() {
    const { currentUser } = useAuth();
    const userId = currentUser?.uid;
    const [budgets, setBudgets] = useState<{ id: string, data: firebase.firestore.DocumentData }[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const db = firebase.firestore();

        return db.collection('users').doc(userId).collection('budgets')
            .orderBy('categoryName', 'asc')
            .onSnapshot(snapshot => {
                const fetchedBudgets = snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() }));
                setBudgets(fetchedBudgets);
                setLoading(false);
            });
    }, [userId]);

    return { budgets, loading };
}

export async function saveBudget(userId: string, budgetId: string | undefined, budget: { categoryName: string, limit: number }) {
    const db = firebase.firestore();

    if (budgetId) {
        // Update existing budget
        await db.collection('users').doc(userId).collection('budgets').doc(budgetId).update(budget);
    } else {
        // Add new budget
        await db.collection('users').doc(userId).collection('budgets').add(budget);
    }
}
